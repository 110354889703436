export const environment = {
  production: true,
  apiBaseUrl: 'https://evt.agorava.cloud.slb-ds.com',
  isAutomationTesting: false,
  appConfigApiUrl: '',
  identityProviderUrl:'https://p4d.csi.cloud.slb-ds.com/v2',
  identityProviderClientKey: '2e874ac41ad98297c018857cfc7abf89',
  identityProviderClientSecret:'8e912b5eee114707b12365e39e3102704b8ea94fb141071f6',
  identityProviderRedirectUrl: 'https://evt.agorava.cloud.slb-ds.com/login/logincallback'
};
